/* Nutzerdefiniertes SCSS und Standard-Bootstrap-SCSS-Module importieren */
@font-face {
  font-family: 'Open Sans';
  src: url(../fonts/OpenSans-VariableFont_wdth,wght.ttf);
}

@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  src: url(../fonts/OpenSans-Italic-VariableFont_wdth,wght.ttf);
}

/* Bootstrap Funktionen (_functions.scss) und Variablen (_variables.scss) importieren */
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';

/* Themespezifische Anpassungen importieren */
@import 'custom';

/* Themespezifische Anpassungen importieren */
@import '~bootstrap/scss/bootstrap';

/* Mixins importieren */
@import 'mixins/responsive-borders';
@import 'mixins/buttons';